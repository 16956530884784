import React, { useState } from "react";
import styles from "./Row.module.scss";
import Info from "../../../../../../img/IconComponents/Info.jsx";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getRequest from "../../../../../../helpers/getRequest";

const Row = ({ orderDate, amount, tokens, id, is_coinbase, representational_id }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const getPdf = (id, representational_id) => {
    setLoading(true);
    getRequest(`v1/orders/pdf/${id}`, "blob")
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Rechnung-${representational_id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("Error downloading the PDF:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.row}>
      <div>
        {localStorage.getItem("i18n") == "EN"
          ? moment(orderDate).format("DD.MM.YYYY hh:mm a")
          : moment(orderDate).format("HH:mm [Uhr], DD.MM.YYYY")}
      </div>
      <div>
        {tokens} {t("Tokens")}
      </div>
      <div>
        {amount.toLocaleString("en-US").replace(",", ".")} EUR
        <div>
          <button onClick={() => getPdf(id, representational_id)} disabled={loading}>
            {loading ? (
              <svg
                width="1024"
                height="1024"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.loading}
              >
                <path
                  fill="#509c5b"
                  d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32m0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32m448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32m-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32M195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248m452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248M828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0m-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none">
                  <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                  <path
                    fill="#509c5b"
                    d="M8 5v2H5v13h14V7h-3V5h3a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2zm4-3a1 1 0 0 1 1 1v10.828L14.828 12a1 1 0 1 1 1.415 1.414l-3.36 3.359a1.25 1.25 0 0 1-1.767 0l-3.359-3.359A1 1 0 1 1 9.172 12L11 13.828V3a1 1 0 0 1 1-1"
                  />
                </g>
              </svg>
            )}
          </button>
          <Link to={`/order/${id}`}>
            <Info />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Row;
