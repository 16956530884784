import React, { useState, useEffect } from "react";
import styles from "./Main.module.scss";
import Webcam from "../Webcam/Webcam";
import News from "../News/News";
import { FRONT_URL } from "../../../../http";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import getRequest from "../../../../helpers/getRequest";
import Button from "../../../../components/Button/Button";
import Cross from "../../../../img/IconComponents/Cross";
import copyIcon from "../../../../img/copy.svg";
import warningIcon from "../../../../img/info_black.svg";
import canabis from "../../../../img/coins_2.png";
import nft from "../../../../img/canabis1.png";
import thumbsUp from "../../../../img/Layer_1.svg";
import DashboardBanner from "../../../../components/DashboardBanner/DashboardBanner";

const Main = ({ currentLang, role }) => {
  const [mlmLink, setMlmLink] = useState("");
  const [id, setId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

// console.log('ROLEROLEROLEROLEROLEROLEROLEROLER', role)
  //check if user has calculator visible
  useEffect(() => {
    getRequest("v1/auth/whoami")
      .then((res) => {
        if (res.data.isCalculatorShown === true) {
          localStorage.setItem("isCalcShown", true);
        } else {
          localStorage.removeItem("isCalcShown");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    getRequest("v1/referrals/my-mlm")
      .then((response) => setMlmLink(response.data?.mlm_link))
      .catch((error) => console.log(error));

    getRequest("v1/auth/whoami")
      .then((res) => {
        setId(res.data?.id);
      })
      .catch((err) => console.log(err));
  }, []);

  const copyToClipboard = () => {
    const url = `${FRONT_URL}/register/ref/${id}`;
    navigator.clipboard.writeText(url);
    setIsCopied(true);
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, [2000]);

    return () => {
      clearTimeout(timer);
    };
  };

  const copyAndClose = () => {
    copyToClipboard();
    const timer = setTimeout(() => {
      setOpenModal(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <section className={styles.dashboard}>
      <div className={styles.dashboard__warning}>
        <img src={warningIcon} alt="warning" />
        <p>
          {t(
            "Current: Hall expansion almost completed - next press conference soon"
          )}
        </p>
      </div>
      <News currentLang={currentLang} role={role} />
      <h2 className={styles.dashboard__startnow_title}>
        {t("Benefit now from Germany's largest cultivation area")}
      </h2>
      <div className={styles.dashboard__startnow}>
        <div>
          <div>
            <img src={canabis} alt="canabis" />1
          </div>
          <p>{t("Purchase the German Cannabis Token (DECT) now")}</p>
        </div>
        <div>
          <div>
            <img src={nft} alt="nft" />2
          </div>
          <p>
            {t("Allocation of a real")}{" "}
            {t("plant")}
          </p>
        </div>
        <div>
          <div>
            <img src={thumbsUp} alt="canabis" />3
          </div>
          <p id="cannabisToken">{t("Lifetime harvest yields received")}</p>
        </div>
      </div>
      <DashboardBanner />

      <Webcam />
      {mlmLink && (
        <div className={styles.dashboard__dect}>
          <p>{t("Sell DECT-Tokens now and generate passive income")}</p>
          <Button
            to={mlmLink}
            text="To DECT Distribution Website"
            color="green"
            blank
          />
        </div>
      )}
      <div className={`${styles.dashboard__referral} ${styles.mobile}`}>
        <p>{t("Invite friends and receive 0.05 DECT worth €125.")}</p>
        <Button
          text="Invite Now"
          color="referral"
          onClick={() => setOpenModal(true)}
        />
      </div>
      {openModal ? (
        <div className={styles.modal}>
          <div className={styles.modal__inner}>
            <button
              className={styles.modal__inner_close}
              onClick={() => setOpenModal(false)}
            >
              <Cross />
            </button>
            <div className={styles.modal__inner_title}>
              {t("Your referral")}
            </div>
            <div className={styles.modal__inner_wrapper}>
              <div
                className={styles.modal__inner_code}
              >{`${FRONT_URL}/register/ref/${id}`}</div>
              <button
                className={styles.modal__copy}
                onClick={() => copyToClipboard()}
              >
                <img src={copyIcon} alt="copy" />
              </button>
            </div>
            <div className={styles.modal__copy_container}>
              <div
                className={cn(
                  styles.modal__copy_state,
                  isCopied ? styles.modal__copy_state_active : ""
                )}
              >
                {t("Copied to clipboard")}
              </div>
            </div>
            <div className={styles.modal__btn} onClick={copyAndClose}>
              {t("Copy referral link and close window")}
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default Main;
