/** @format */

import React, { useState } from 'react';
import styles from './New.module.scss';
import { API_URL } from '../../../../http';
import moment from 'moment';
import ArrowIcon from '../../../../img/IconComponents/ArrowIcon';
import { useTranslation } from 'react-i18next';

const New = ({ item }) => {
  const { t } = useTranslation();
  const {
    id,
    title,
    content,
    created_at,
    image_id,
  } = item;
  const [opened, setOpened] = useState(false);
  return (
    <div className={styles.new} key={id}>
      <div className={styles.new__image}>
        <img
          src={`${API_URL}/v1/local-files/${image_id}`}
          alt={id}
        />
      </div>
      <div className={styles.new__body}>
        <p className={styles.new__body_date}>
          {moment(created_at).format(
            'DD.MM.YYYY',
          )}
        </p>
        <div className={styles.new__body_inner}>
          <h3
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            className={styles.new__body_title}
          />
          <p
            className={styles.new__body_desc}
            style={{
              WebkitLineClamp: opened ? 100 : 2,
              maxHeight: opened
                ?'10000px'
                : '0px',
            }}
            dangerouslySetInnerHTML={{ __html: content  }}
          />
          <div
            className={styles.new__body_link}
            onClick={() =>
              setOpened((prev) => !prev)
            }>
            {opened
              ? t('Hide Details')
              : t('View Details')}{' '}
            <ArrowIcon
              color='#509c5b'
              rotate={opened ? '-90deg' : '90deg'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
